import './App.css';
import React, { useState } from 'react';
import DailyMovie from './components/dailyMovie/dailyMovie';
import Header from './components/header/header';

function App() {
  const [currentLang, setCurrentLang] = useState('en');

  const handleChangeLanguage = (newLang) => {
    setCurrentLang(newLang);
  };
  return (
    <div className="App">
      <Header onChangeLanguage={handleChangeLanguage} />
      <DailyMovie currentLang={currentLang} />
    </div>
  );
}

export default App;