import React, {useState} from 'react'
import logo from '../../../src/dailyMovie-logo.png'

function Header({ onChangeLanguage }) {
  const [currentLang , setCurrentLang] =  useState('en');
  const checkLang = ((lang) => {
    setCurrentLang(lang);
    onChangeLanguage(lang);
  })

  window.lang = {
    lang: currentLang === 'es' ? 'es-ES' : 'en-US'
  }

  return (
    <div className='headerContainer'>
      <img src={logo} alt='Logo DailyMovie' className='titleHeader'></img>
      <div className="demo">
        <div>
          <table className="lang">
          <tbody>
              <tr>
                <td onClick={() => checkLang('en')}><span id="en" className={`button_lang ${currentLang === 'en' ? 'current_lang' : ''}`}>EN</span></td>
                <td onClick={() => checkLang('es')}><span id="es" className={`button_lang ${currentLang === 'es' ? 'current_lang' : ''}`}>ES</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    
  )
}

export default Header