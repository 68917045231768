import React, {useEffect, useState} from 'react';
import Yotube from "react-youtube";
import schedule from 'node-schedule';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBHv3W0NOThhJWizpcHY9zTirLWdykXDF4",
  authDomain: "dailymovie-ad5f7.firebaseapp.com",
  projectId: "dailymovie-ad5f7",
  storageBucket: "dailymovie-ad5f7.appspot.com",
  messagingSenderId: "56349869023",
  appId: "1:56349869023:web:37817605a6a780a7547a6a",
  measurementId: "G-DY47CJJCQV"
};

firebase.initializeApp(firebaseConfig);

function DailyMovie({currentLang}) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [playTrailer, setPlayTrailer] = useState(null);

  useEffect(() => {
    const fetchMovieData = async () => {
      try {
        const db = firebase.firestore();
        const movieRef = db.collection('movies').doc('dailyMovie');
        const doc = await movieRef.get();

        if (doc.exists) {
          setData(doc.data());
        }

        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchMovieData();
  }, []);

  const loadVideo = () => {
    const trailer = currentLang == 'es' ? data.esVideo.find(vid => vid.name.includes("Trailer")  || vid.name.includes('Tráiler')) : data.enVideo.find(vid => vid.name.includes("Trailer")  || vid.name.includes('Tráiler'));
    const key = trailer ? trailer.key : data.video[0].key;

    return (
      <Yotube 
        videoId= {key}
        opts={
          {
            width:'100%',
          }
        
          }
      />
    )
  }

  if (isLoading) {
    return (
      <div className="App">
        <h1>Cargando...</h1>
      </div>
    );
  }

  return (
    <div className="dailyMovie">
      {/* Renderiza tus componentes usando los datos recuperados de Firestore */}
      <div className='dailyMovieBackground' style={{backgroundImage:`url(${'https://image.tmdb.org/t/p/original' + data.en.image })`}}>
        <div className="dailyMovieContainer">
          <div className="dailyMovieTitle">{currentLang == 'es' ? data.es.text : data.en.text}</div>
          <div className="movieBlock">Movie</div>
          <div className="dailyMovieDescription">{currentLang == 'es' ? data.es.overview : data.en.overview}</div>
          <div className='dailyTrailerContainer'>
            {data.enVideo && playTrailer ? loadVideo() : false}
              <button className='playButton' onClick={() => setPlayTrailer(true)}>Trailer</button>
            {playTrailer ? <button className='playButton close' onClick={() => setPlayTrailer(false)}>Close</button> : false}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DailyMovie;